/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Container from '@material-ui/core/Container';

import Header from "../components/header"
import * as styles from "./global-layout.module.css"

const GlobalLayout = ({ children }) => {
  const logo = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "logo.svg"}) {
        publicURL
        internal {
          mediaType
        }
      }
    }
  `)
  return (
    <Container>
      <Header />
      <embed
        type={logo.file.internal.mediaType}
        src={logo.file.publicURL}
        className={styles.logo}
        alt="logo" />
      <div className={styles.layoutContainer}>
        <main>{children}</main>
        <footer className={styles.layoutFooter}>
          © {new Date().getFullYear()} K.S.K. All rights reserved., Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer>
      </div>
    </Container>
  )
}

GlobalLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default GlobalLayout
