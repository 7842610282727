import * as React from "react"
import { Link } from "gatsby"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faFolderOpen, faSortAlphaDown } from "@fortawesome/free-solid-svg-icons"
import * as styles from "./header.module.css"

const Header = () => {
  return (
    <div className={styles.breadcrumb}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/">
          <FontAwesomeIcon icon={faHome} size="sm"/>
          <span className={styles.breadcrumbItem}>Top</span>
        </Link>
        <Link to="/page/1/">
          <FontAwesomeIcon icon={faFolderOpen} size="sm"/>
          <span className={styles.breadcrumbItem}>All Posts</span>
        </Link>
        <Link to="/keywords/">
          <FontAwesomeIcon icon={faSortAlphaDown} size="sm"/>
          <span className={styles.breadcrumbItem}>Keywords</span>
        </Link>
      </Breadcrumbs>
    </div>
  )
}

export default Header
